import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Image from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { StaticImage } from 'gatsby-plugin-image';

import infoContact from "./../data/infoContact.json"


const Container = styled.section`
    padding:6rem 0;
    max-width:1300px;
    width:95%;
    margin:0 auto;
    @media(max-width:768px){
        max-width:720px;
    }
    @media(max-width:992px){
        max-width:960px;
    }
    @media(max-width:1200px){
        max-width:1140px;
    }
`

const ALink = styled.a`
    color: #99C1DA;
    text-decoration: none;
    :hover{
        color: #FFFFFF;
        text-decoration: underline;
        transition: color .15s ease-in-out;
    }
`

const ALinkMedia = styled.a`
    color: #99C1DA;
    text-decoration: none;
    :hover{
        color: #6D8AA4;
        transition: color .15s ease-in-out;
    }
`

const Footer = () => {

    // const { image, allStrapiHomePage } = useStaticQuery(graphql`
    //     {
    //         image: file(relativePath: { eq:"LogoWhite.png" } ) {
    //             sharp:childImageSharp {
    //             fluid(maxWidth: 400, quality: 100) {
    //                 ...GatsbyImageSharpFluid
    //             }
    //             }
    //         },
    //         allStrapiHomePage {
    //             nodes {
    //                 correo
    //                 instagram
    //                 telefono
    //                 facebook
    //             }
    //     }
    //     }
    // `)

    // const { telefono, correo, instagram, facebook } = allStrapiHomePage.nodes[0]

    const year = new Date().getFullYear();

    return (
        // <footer css={css`background-color:#0E1230;`}>
        <footer>
            <div css={css`line-height:0;`}>
                <svg viewBox="0 0 1918 407.39">
                    <defs>
                        <clipPath id="clip-path">
                            <path css={css`fill: none;`} d="M1199.94,357.68C875.59,357.68,630,274,371.92,274c-179.2,0-309.24,33-372,53.1L0,785.82H1918V161.53c-183.47,108-373,196.15-718.06,196.15" />
                        </clipPath>
                        <linearGradient id="linear-gradient" x1="958.5" y1="392.81" x2="959.5" y2="392.81" gradientTransform="matrix(1920.05, 0, 0, -1920.05, -1840363.53, 754490.78)" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="#0E1230" />
                            <stop offset="0.06" stop-color="#0E1230" />
                            <stop offset="1" stop-color="#0E1230" />
                        </linearGradient>
                    </defs>
                    <title>RayoDentSVGFooter</title>
                    <path css={css`fill: #0071CE;`} d="M1246.22,357.64S1619.79,324,1918,0V399.64H1246.22Z" />
                    <path css={css`fill: #FFBE85;`} d="M0,275s188.79-54.75,405.31,0C181.33,349.62,0,400.64,0,400.64Z" />
                    <g css={css`clip-path: url(#clip-path);`}>
                        <rect css={css`fill: url(#linear-gradient);`} x="0.13" y="161.77" width="1917.88" height="245.62" />
                    </g>
                </svg>
            </div>
            <div css={css`background-color:#0E1230; position:relative;`}>
                <Container>
                    <div
                        css={css`
                        padding:0 3rem;
                        @media (min-width:768px){
                        display: grid;
                        align-items:center;
                        grid-template-columns: 1fr 1fr;
                        column-gap:4rem;
                    };
                `}>
                        <div css={css`
                            text-align:center;
                            justify-content:left;
                            @media (min-width:768px){
                            text-align:center;
                            justify-content:center;
                            }
                        `}>
                            <StaticImage css={css`
                                max-width:70%;
                                text-align:center;
                                height:auto!important;
                                @media (max-width:412px){
                                    max-width:90%!important;
                                }
                                @media (max-width:1200px){
                                    margin:auto !important;
                                }
                              `} src="../images/LogoWhite.png" />
                        </div>
                        <div css={css`
                            text-align:center;
                            @media (min-width:1200px){
                            text-align:right;
                            }
                        `}>
                            <p>
                                <ALinkMedia href={infoContact.instagram} rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} size="2x" /></ALinkMedia>
                                <ALinkMedia css={css`padding:0 1rem;`} href={"tel:" + infoContact.phone} rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faPhone} size="2x" /></ALinkMedia>
                                <ALinkMedia css={css`padding-right:1rem;`} href={"mailto:" + infoContact.email + "?subject=Solicitud de información"} rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faEnvelope} size="2x" /></ALinkMedia>
                                <ALinkMedia css={css`padding-right:1rem;`} href={infoContact.facebook} rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebookF} size="2x" /></ALinkMedia>
                            </p>
                        </div>
                    </div>
                    <div css={css`text-align:center;`}>
                        <p><span css={css`color:#99C1DA ;`}>&copy; {year} Rayodent.</span> <span css={css`color:#6D8AA4;`}>Diseñado y desarrollado por</span> <ALink href="https://aurorastudio.tech" rel="noopener noreferrer" target="_blank">Aurora Studio</ALink></p>
                    </div>
                </Container>
            </div>
        </footer>
    );
}

export default Footer;